.authpage {
  background: #000 url(../olivebg.png) no-repeat;
  background-size: cover;
  height: 100vh;
  position: relative;
  overflow-x: hidden;
  background-size: cover;

  font-family: 'Raleway', Lato, Source Sans Pro, Helvetica Neue, Helvetica,
    Arial;
  .authcontainer {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 9;
    padding: 20px;
    h1 {
      font-size: 21px;

      margin-bottom: 5px;
      font-weight: normal;
      text-align: center;
      text-transform: uppercase;
    }
    .auth {
      width: 400px;
      margin: 0 auto;
      max-width: 100%;
      background: var(--admin-primary-color);
      padding: 30px;
      color: white;
      form {
        margin: 40px 0;
        label {
          font-size: 16px;
        }
        input {
          padding-left: 5px;
          border-top-width: initial;
          border-right-width: initial;
          border-left-width: initial;
          border-top-color: initial;
          border-right-color: initial;
          border-left-color: initial;
          color: rgb(0, 0, 0);
          border-radius: 0px;
          border-style: none none solid;
          border-image: initial;
          border-bottom: 1px solid rgb(186, 186, 186);
          background-color: #f1f1f1;
        }
        button {
          border-radius: 0px;
          border: none;
          background: var(--admin-secondary-color);
          width: 100%;
          display: block;
          color: rgb(255, 255, 255);
          mix-blend-mode: normal;
          text-align: center;
          padding: 10px;
          margin: 0 auto;
        }
      }

      .logo {
        width: 180px;
        text-align: center;
        margin: 0 auto 30px;
        img {
          max-width: 180px;
        }
      }

      i {
        margin-right: 5px;
      }
    }
  }
}
