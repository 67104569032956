#newsfeed {
  .postCreator {
    display: block;
    @extend .shadowBox;
  }

  .postForm {
    display: flex;
    padding: 15px 5px;
    background: #ffffff;
    flex-direction: column;
    color: var(--black-color);

    header {
      display: flex;
      flex-direction: row;
      flex-direction: row;
      justify-content: left;
      align-items: flex-start;
      .img {
        img {
          width: 40px;
          height: 40px;
        }
      }
      .rightSide {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        button.link {
          margin-left: 10px;
        }
      }
      h3.name {
        font-size: 16px;
        font-weight: bold;
        margin: 0px;
      }
      h4 {
        color: var(--gray-color);
        font-size: 16px;
        font-weight: normal;
        margin: 0px;
        line-height: 40px;

        cursor: pointer;
      }
      .sharing {
        display: flex;
        flex-direction: row;
        font-size: 12px;
        font-weight: normal;

        strong {
          font-weight: normal;
          display: flex;
          align-items: center;
          padding-right: 8px;
        }
      }
    }
    .btns {
      display: flex;
      align-items: center;
    }
    .cont {
      display: flex;
      justify-content: center;
      padding-left: 50px;
      position: relative;
      flex: 1 1 auto;
      flex-direction: column;
      color: var(--black-color);
      .warnimgMsg {
        font-size: 13px;
        display: flex;
        padding: 10px 0;
        justify-content: flex-start;
        align-items: center;
        color: var(--olive-color);
        img {
          margin-right: 5px;
        }
      }
      img.close {
        position: absolute;
        right: 0px;
        top: -1px;
      }
      img.options {
        position: absolute;
        right: 30px;
        top: -1px;
      }
      button.skip {
        font-size: 12px;
        color: var(--black-color);
        background: var(--border-color);
        border: none;
        display: inline-block;
      }
      .createBtnRow {
        display: flex;
        flex-direction: row;
        .btn {
          height: 28px;
          font-size: 12px;
          line-height: 28px;
        }
      }
      input.postTitle {
        font-size: 20px;
        color: var(--black-color);
        font-weight: normal;
        border: none;
        margin: 20px 0 15px 0;
        &::placeholder {
          color: var(--black-color);
        }
        &:focus {
          border: none;
          outline: none;
        }
      }
      textarea.postCont {
        font-size: 16px;
        color: var(--black-color);
        border: none;
        min-height: 150px;

        &::placeholder {
          color: var(--black-color);
        }
        &:focus {
          border: none;
          outline: none;
        }
      }

      /*
        ==================================================================
                        Post Carousel
        ==================================================================
        */
      .postCarousel {
        display: flex;
        margin-top: 20px;
        height: 100px;
        .fileLink {
          &:hover {
            text-decoration: none;
          }
        }
        .rec-item-wrapper {
          img,
          video {
            height: 100px;
            width: 100px;
          }
          img.doc {
            width: auto;
            height: auto;
          }
          button {
            z-index: 99;
            img {
              height: auto;
              width: auto;
            }
          }
        }
        .rec-slider > div {
          display: flex;
          justify-content: center;
          align-self: center;
        }
        .playBtn {
          display: flex;
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0px;
          top: 0px;
          z-index: 9;
          justify-content: center;
          align-items: center;
          img {
            width: 36px;
            height: 36px;
          }
        }
        .rec-item-wrapper > div {
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          background-color: var(--light-gray-color);
          height: 100px;
          justify-content: center;
          width: 100px;
          height: 100px;

          strong {
            font-weight: normal;
            line-height: 12px !important;
            margin-top: 7px;
            font-size: 10px;
            overflow: hidden;
            width: 94px;
            text-overflow: ellipsis;
            display: block;
            height: 15px;
          }
        }
        .rec-arrow-right,
        .rec-arrow-left {
          width: 20px;
          height: 100px;
          background: var(--border-color);
          border-radius: 0px;
          padding: 0px;
          margin: 0px;
          min-width: 20px;
          max-width: 20px;
          font-size: 10px;
          border: none;
          box-shadow: 0 0 0 transparent;
        }
        .fileLink {
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          color: var(--black-color);
        }
      }
    }
    .footer {
      display: flex;
      flex-direction: row;
      margin: 29px 0 10px 50px;

      ul {
        list-style: none;
        margin: 0px;
        padding: 0px;
      }
      .leftNav {
        width: 40%;
        display: flex;
        flex-direction: row;
        li {
          padding-right: 15px;
          input[type='file'] {
            display: none;
          }
        }
      }
      .rightNav {
        width: 60%;
        flex-direction: row;
        justify-content: flex-end;
        display: flex;
        .datedisplay {
          font-size: 11px;
        }
        li {
          padding-left: 15px;
          button.postNow {
            font-size: 14px;
            color: white;
            font-weight: bold;
            height: 30px;
            line-height: 30px;
            background: var(--blue-color);
            padding-left: 23px;
            padding-right: 23px;
            border: none;
            &[disabled] {
              background: var(--light-gray-color);
              color: var(--gray-color);
            }
          }
        }
      }
    }
  }
  .ck-editor {
    display: flex;
    flex-direction: column-reverse;
    .ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
      border-color: white;
    }
    .ck.ck-editor__main > .ck-content {
      min-height: 200px;
    }
    .ck.ck-toolbar {
      border-color: white;
    }
  }

  /* Mobile Portait */
  @media only screen and (min-width:320px) and (max-width: 480px) {
    #newsfeed .postForm {
      border: solid 1px red;

      .footer {
        .leftNav,
        .rightNav {
          width: 50%;
        }
      }
    }
  }
}
