@media only screen and (min-width: 290px) and (max-width: 319px) {
  #root {
    #newsfeed {
      #newsfeed .ck-editor .ck.ck-editor__main > .ck-content {
        padding: 0px !important;
      }
      .dropDown {
        min-width: 220px;
      }
      /* Schedule Picker */
      #schedulePicker {
        padding: 8px;
        h3 {
          font-size: 16px;
        }
        .calendarConfig {
          padding: 8px;
          .leftConfig {
            margin-bottom: 8px;
          }
        }
        .displayCalendar {
          padding: 0px 8px 8px 8px;
        }
        .actionControl {
          flex-direction: column;
          .rightSide {
            flex-direction: column;
            .selectedDate {
              padding: 8px;
              .selectedDate {
                button {
                  width: 100%;
                }
              }
            }
          }
          .leftSide {
            flex-direction: column;
            justify-content: center;
            display: flex;
            align-items: center;
            button {
              width: 180px;
              margin-top: 5px;
            }
          }
        }
      }
      /* Post Form */
      .postForm {
        padding: 8px;
        header {
          .sharing {
            flex-direction: column;
          }
        }
        .cont {
          padding-left: 0px;
          input.postTitle {
            font-size: 16px;
          }
        }
        .footer {
          margin: 8px;
          flex-direction: column;
          .rightNav {
            width: 100%;
            
            justify-content: flex-start;
            margin-top: 8px;
            flex-direction: column;
            li {
              padding-left: 0px;
              position: relative;
              &:first-child {
                flex-direction: row;
              }
              button{
                margin-bottom:5px;
              }
              button.postNow {
                width: 100%;
              }
              button.link {
                position: absolute;
                top: -40px;
                left: 120px;
              }
            }
          }
        }
      }
      .post {
        margin: 0 0 0 0 !important;
        padding: 5px 0 5px 0;
        .actionButtons {
          margin: 0 8px 0px 8px;
          padding: 8px 0 8px 0;
        }
        header {
          margin: 8px 0px 8px 8px;
        }
        .fileList {
          margin: 0 8px;
          .item {
            .desc {
              border: none;
              padding: 0px 0 0 5px;
            }
            .icon {
              padding-left: 5px;
              img {
                width: 16px;
              }
            }
          }
        }
        .cont {
          padding: 0 8px;
          .pollResult,
          .pollQuestion {
            .header {
              padding: 8px 0;
              h4.title {
                margin-left: 8px;
              }
            }

            h4.ques {
              margin-left: 8px;
            }
            .pollOptions {
              .option {
                padding: 8px 0;
              }
            }
          }
        }
        /* Review and Short Info */
        .shortInfo,
        .actionButtons {
          margin: 0 8px 0px 8px !important;
          padding: 8px 0 !important;
        }
      }
      /* Comments  */
      .commentBox {
        padding: 8px;
        .userCont {
          padding: 8px 8px 8px 14px;
        }
        .commentBox {
          padding: 0px;
        }
        .commentForm {
          border-top: solid 2px var(--light-gray-color);

          .rightSide {
            padding: 0px;
            button {
              width: 30px;
              height: 30px;
              margin-top: 9px;
              img {
                width: 25px;
                height: auto;
                display: block;
                position: relative;
                left: -13px;
              }
              span {
                display: none;
              }
            }
          }
        }
        .userInfo {
          padding-left: 5px;
          flex-direction: column;
        }
        .userComment {
          .commentForm {
            width: 100%;
            flex-direction: column;
            margin-top: 10px;
            .comment {
              max-width: 100%;
            }
            .rightSide {
              width: 90px;
              button {
                width: 90px;
              }
            }
          }
        }
      }
      /* Poll */
      #poll {
        margin: 8px 0px 0 0px;
        padding: 8px 0 8px 0px;
        .cont {
          .pollResponse {
            flex-direction: column;
            align-items: flex-start;
            .selectbox {
              margin-left: 0px;
            }
          }

          .pollQuestion {
            margin-bottom: 5px;
          }
          .pollOptions {
            .userImg {
              width: 40px;
              height: 40px;
              margin: 0px;
              img {
                width: 35px;
                height: 35px;
              }
            }
          }
        }
      }
      .confirmBox .box,
      .loader .box {
        width: 100%;
        h2 {
          font-size: 16px;
        }
      }
    }
  }
}

@media screen and (min-width:250px) and (max-width:480px){
  #root #newsfeed .postForm .footer{
    margin:8px 0;
  }
}