/*
==================================================================
                        Schedule Calendar
==================================================================
*/
#newsfeed {
  #schedulePicker {
    clear: both;
    background: var(--light-gray-color);
    padding: 30px;
    display: flex;
    flex-direction: column;
    margin-bottom: 1px;
    h3 {
      font-size: 20px;
    }
    .colon {
      padding: 0 10px;
    }
    .selectbox {
      height: 30px;
      border-radius: 0px;
      background-color: var(--light-gray-color);
      border: none;
      font-size: 14px;
      &.am {
        margin: 0 5px;
      }
      &.timezone {
        margin: 0 15px;
      }
      &.month {
        min-width: 217px;
        padding-left: 10px;
        @media screen and (min-width:320px) and (max-width:414px){
          margin-top:5px;
        }
      }
    }
    .calendarConfig {
      background: white;
      display: flex;
      flex-direction: row;
      min-height: 70px;
      padding: 10px 30px 20px 30px;
      align-items: center;
      justify-content: space-between;

      margin-bottom: 1px;
      flex-wrap: wrap;
      .hour,
      .minute {
        width: 40px;
        height: 36px;
        background-color: var(--light-gray-color);
        border: none;
        text-align: center;
      }
      .leftConfig {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-top:10px;
        padding-right:10px;
        & ~ div {
          padding-top:10px;
          @media screen and (min-width:320px) and (max-width:650px){
          display:flex;
          flex:1;
          
        
         
          }
          @media screen and (min-width:320px) and (max-width:480px){
            display:flex;
            flex:1;
            
            
           
            }
         
        }
      }
    }
    .displayCalendar {
      padding-top: 20px;
      background: white;
      padding: 0 30px 20px 30px;
      display: flex;
      flex-direction: column;
      ul.weeks {
        margin: 0px;
        padding: 20px 0 10px 0;
        list-style: none;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
      }
      ul.days {
        display: block;
        margin: 0px;
        padding: 0px;
        list-style: none;

        li {
          width: 14.28%;
          display: block;
          float: left;
          text-align: center;
          padding: 6px 0;

          &.previousMonthDate,
          &.nextMonthDate,
          &.disabled {
            color: #9197a3;
            pointer-events: none;
            &.current {
              pointer-events: none;
            }
          }
          &.current {
            cursor: pointer;
            color: var(--primary-color);
          }
          &.active {
            cursor: pointer;
          }
          &.active.selected span {
            background: var(--primary-color);

            color: white;
          }
          &.active:hover span {
            background: var(--light-gray-color);

            color: var(--black-color);
          }
          span {
            display: inline-block;
            min-width: 30px;
            min-height: 30px;
            border-radius: 30px;
            line-height: 30px;
          }
        }
      }
    }
    .actionControl {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 10px 0px 20px 0;
      @media screen and (min-width:320px) and (max-width:768px){
        display: flex;
        flex-direction: column;
      }
      .leftSide {
        @media only screen and (min-width: 320px) and (max-width: 768px) {
          display: flex;
          justify-content: space-between;
       

        }
        button {
          margin-right: 5px;
          margin-top:10px;
          &:last-child{
            @media only screen and (min-width: 320px) and (max-width: 768px) {
              margin-top:5px;         
          }
          }
        }
      }
      button {
        margin-top: 10px;
      }
      .rightSide {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        @media only screen and (min-width: 320px) and (max-width: 768px) {
    flex-direction: column;
    align-items: center;

}
        .selectedDate {
          padding-right: 12px;
          font-size: 12px;
          margin-top:10px;
        

        }

        .btn-dark{
          @media screen and (min-width:320px) and (max-width:768px){
            width:100%;
          }
        }
        
      }
    }
  }
  /*
==================================================================
                        Schedule Post List
==================================================================
*/
  #schedulePostList {
    display: block;
    @extend .shadowBox;
    .trigger {
      padding: 15px 5px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .leftSide {
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: center;
        img {
          margin-right: 16px;
          cursor: pointer;
        }
        strong {
          color: var(--black-color);
        }
      }
      .rightSide {
        img {
          cursor: pointer;
        }
      }
    }
    .list {
      padding: 0 18px;
    }
    .all {
      color: var(--gray-color);
      text-align: center;
      font-size: 12px;
      padding: 0px 0 10px 0;
      position: relative;
      cursor: pointer;
      &:before {
        content: '';
        display: block;
        border-bottom: solid 1px var(--border-color);
        position: absolute;
        top: 8px;
        left: 0px;
        width: 100%;
      }
      span {
        background: white;
        padding: 0 25px;
        position: relative;
        z-index: 5;
        a {
          color: var(--gray-color);
        }
      }
    }
  }

  /* Mobile Portait */
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    #schedulePicker {
      padding: 10px;
      justify-content: center;
      .calendarConfig {
        padding: 10px 15px;
        flex-direction: column;
        .leftConfig {
          justify-content: flex-start;
          margin-bottom: 10px;
        }
      }
      .actionControl {
        flex-direction: column;
        .rightSide {
          margin-top: 10px;
          justify-content: space-between;
        }
      }
    }
  }
}
