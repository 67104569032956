.confirmBox,
.loader {
  position: fixed;
  background: rgba(255, 255, 255, 0.8);
  top: 0px;
  left: 0px;
  z-index: 99;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  h2 {
    font-size: 18px;
    padding: 0 10px;
  }
  .box {
    width: 500px;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background: white;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    header {
      margin-bottom: 25px;
    }
  }
  button.closeBtn {
    position: fixed;
    top: 15px;
    right: 15px;
    background: none;
    border: none;
    padding: 10px;
    margin: 0px;
  }
}
.overflowHidden {
  overflow: hidden;
}
@media only screen and (min-width: 320px) and (max-width: 480px) {
  .confirmBox .box {
    width: 90%;
  }
}
