.adminRightCol {
  font-family: 'Raleway', Lato, Source Sans Pro, Helvetica Neue, Helvetica,
    Arial;
  background: white;

  .bp-form {
    width: 70%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    label {
      font-size: 18px;
      margin-top: 15px;
    }
    input[type='text'],
    select {
      border-radius: 0px;
      border-width: 2px;
      height: 40px;
    }
    textarea {
      border-radius: 0px;
      border-width: 2px;
      min-height: 240px;
    }
    button,
    .btn {
      min-width: 150px;
      border: none;
      height: 40px;
      line-height: 26px;
      border-radius: 0px;
      background: var(--admin-primary-color);
      color: white;

      &.btn-secondary {
        background: var(--admin-primary-light-color);
        color: white;
      }

      &.btn-outline-secondary {
        color: var(--admin-primary-color);
        background: transparent;
        border: solid 2px var(--admin-primary-light-color);
      }

      &.ck {
        min-width: auto;
        border: inhert;
        height: auto;
        line-height: inherit;
        border-radius: inherit;
        background: inherit;
        color: #333;
      }
    }
  }
  .fileList {
    display: flex;
    flex-wrap: wrap;
    margin-left: -5px;

    button.triggerBtn {
      padding: 0px;
      margin: 0px;
      background: none;
      border: none;
      width: 200px;
      height: 200px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      i {
        font-size: 25px;
      }
      span.filename {
        word-break: break-word;
        line-height: 18px;
        font-size: 16px;
        padding-top: 10px;
      }
    }

    .fileItem {
      position: relative;
      margin: 5px 0;
      border-radius: 0px;
      img {
        border-radius: 0px;
      }

      a {
        word-break: break-word;
      }
      a.fileBox {
        display: flex;
        width: 200px;
        height: 200px;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        color: var(--admin-primary-light-color);
        i {
          font-size: 40px;
        }
        span {
          font-size: 13px;
          padding: 10px;
          text-align: center;
        }
      }
      &.videoThumbnailUrl {
        i {
          position: absolute;
          left: 50%;
          top: 50%;
          font-size: 37px;
          font-weight: 300;
          width: 40px;
          height: 40px;
          margin: -20px 0 0 -20px;
          display: block;
          background: url(/img/play-dark.svg);
          z-index: 99;
          background-size: contain;
          border-radius: 41px;
        }
        &:hover {
          img {
            opacity: 0.8;
          }
        }
      }
      button.controlBtn {
        display: none;
        flex: 1;
        height: 100%;
        width: 100%;
        justify-content: center;
        position: absolute;
        top: 0px;
        left: 0px;
        background: none;
        img {
          position: relative;
          display: none;
        }
        &:hover {
          opacity: 1;
          &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0px;
            left: 0px;
            background: rgba(0, 0, 0, 1);
            opacity: 0.8;
            height: 100%;
            width: 100%;
          }
          img {
            display: block;
            z-index: 90;
          }
        }
      }
      &:hover {
        button {
          display: flex;
        }
      }
    }
  }
}
.ck-editor .ck-content {
  min-height: 300px;
  strong {
    font-weight: 600;
  }
}
