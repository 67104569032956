:root {
  --admin-primary-color: #000000;
  --admin-primary-light-color: #5c5c5c;
  --admin-lightest-color: #fffded;
  --admin-secondary-color: #201335;
  --admin-secondary-light-color: #4f4789;
  --admin-seperate-color: #ffb17a;
  --admin-higlight-color: #fce762;
  --admin-border-color: rgba(92, 92, 92, 0.2);
  --admin-lightgray-color: rgba(222, 222, 222, 0.6);
}

@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;600;900&display=swap');
@import './sass/admin/clientlist.scss';
@import './sass/admin/admin.scss';
html {
  height: 100%;
}
body {
  height: 100%;
  font-family: Lato, Source Sans Pro, Helvetica Neue, Helvetica, Arial,
    sans-serif;
}
#root {
  display: flex;
  height: 100%;
  flex-direction: column;
}
#root > div {
  display: flex;
  flex: 1;
  flex-direction: column;
}
#root .container-fluid {
  height: 100%;
  flex-wrap: wrap;
}
#root .container-fluid > .row {
  flex: 1;
  flex-wrap: wrap;
  height: 100%;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

h5 {
  font-size: 16px;
  font-weight: 700;
}

p {
  font-size: 14px;
}

.modal {
  font-family: 'Raleway', Lato, Source Sans Pro, Helvetica Neue, Helvetica,
    Arial;

  display: flex !important;
  justify-content: center;
  align-items: center;

  .modal-dialog {
    display: flex;
    height: auto;
    width: 600px;
    box-shadow: 1px 1px 25px #999;
    max-width: 600px;
    border-radius: 0px;
    .modal-content {
      border-radius: 0px;
      max-height: 600px;
      overflow: auto;
      .modal-header {
        text-align: center;
        display: flex;
        justify-content: center;
        border: none;
        h5 {
          font-size: 25px;
          font-weight: 600;
        }
      }
      .modal-body {
        padding: 20px;
        label {
          font-size: 18px;
        }
        input {
          border-radius: 0px;
          &[type='text'] {
            border-width: 2px;
          }
        }
      }
      .modal-footer {
        display: flex;
        border: none;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        padding: 20px;
        .form-group {
          display: flex;

          button {
            i {
              display: none;
            }
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 18px;
            border-radius: 0px;
            min-width: 150px;
            border: none;
            &.btn-primary {
              background: var(--admin-primary-color);
            }
          }
        }
      }
    }
  }
}
/*

.auth-container h1 {
  font-size: 21px;

  margin-bottom: 5px;
  font-weight: normal;
  text-align: center;
  text-transform: uppercase;
}

.auth-container .auth {
  width: 400px;
  margin: 0 auto;
  max-width: 100%;
  background: var(--admin-primary-color);
  padding: 30px;
  color: white;
}

.auth-container .auth form {
  margin: 40px 0;
}

.auth-container .auth form .form-group label {
  font-size: 16px;
}
.auth-container .auth form .with-icon input {
  padding-left: 5px;
  border-top-width: initial;
  border-right-width: initial;
  border-left-width: initial;
  border-top-color: initial;
  border-right-color: initial;
  border-left-color: initial;
  color: rgb(0, 0, 0);
  border-radius: 0px;
  border-style: none none solid;
  border-image: initial;
  border-bottom: 1px solid rgb(186, 186, 186);
  background-color: #f1f1f1;
}
.auth-container .auth button {
  border-radius: 0px;
  border: none;
  background: var(--admin-secondary-color);
  width: 100%;
}
.auth-page .logo {
  width: 180px;
  text-align: center;
  margin: 0 auto 30px;
}

.auth-page .logo img {
  max-width: 180px;
}

.auth-page .bp-form i {
  margin-right: 5px;
}
*/
.btn-base {
  display: block;
  width: 180px;
  color: rgb(255, 255, 255);
  mix-blend-mode: normal;
  text-align: center;
  padding: 10px;
  background: rgb(35, 35, 35);
  border-radius: 30px;
  margin: 0 auto;
}

.btn {
  margin-right: 10px;
}

/*
.navbar-brand {
  width: 120px;

  float: left;
  padding: 10px;
  border-bottom: none;
}

.navbar-brand img {
  max-width: 100%;
}
.navbar {
  background: var(--admin-primary-color);
  border-radius: 0px;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
  min-height: 60px;
}

.navbar-nav .nav-link {
  color: white;
}*/

.rounded-circle {
  width: 32px;
  height: 32px;
}
/*
.sidebar {
  background: var(--admin-secondary-color);
  height: 100vh;
  padding: 70px 0 0 0;
}
.sidebar ul.nav li:nth-child(2n) {
  background: var(--admin-secondary-light-color);
}
.sidebar a {
  color: white;
  padding: 10px 20px;
  width: 100%;
  border: none;
  outline: none;
  text-align: left;
  cursor: pointer;
  transition: 0.3s;
  font-size: 17px;
}

.sidebar a:hover {
  background-color: #fff;
  color: #000000;
}

.sidebar i {
  margin-right: 10px;
}
*/
i {
  margin-right: 5px;
}

.error-flash-message {
  margin-top: 15px;
}

.list-group-item.active {
  background-color: #eff3f3;
  border-color: #eff3f3;
  color: #000;
}

.list-group-item:hover {
  background-color: #eff3f3;
  border-color: #eff3f3;
  color: #000;
}

.list-group-item {
  cursor: pointer;
  border-bottom: 2px solid #fff !important;
}

.client-icon {
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid #c4c4c4;
}

.client-icon img {
  width: 100%;
  height: 100%;
}

.creator-icon {
  overflow: hidden;
}

.creator-icon h1 {
  font-size: 1.2 rem;
}

.creator-icon img {
  width: 100%;
  height: 100%;
}

.tenant-checkbox {
  margin-left: 10px;
}

.loader {
  margin-left: 0px;
  margin-bottom: 0px;
}
.modal {
  display: block;
}
.img-thumbnail {
  height: 200px;
  position: relative;
}

.attachment-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  min-height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
}
.adminRightCol .fileList.box .fileItem {
  margin: 5px;
  width: 200px;
  height: 200px;
}
.adminRightCol .fileList .fileItem img,
.adminRightCol .fileList .fileItem video {
  object-fit: cover;
}
.adminRightCol .fileList .fileItem .attachment-thumbnail a {
  color: var(--admin-primary-light-color);
  display: flex;
  justify-content: space-between;
}
.adminRightCol .fileList .fileItem .attachment-thumbnail i {
  margin-top: 5px;
}
.adminRightCol .fileList .fileItem .attachment-thumbnail span {
  word-break: break-word;
}
.adminRightCol .fileList .fileItem .attachment-thumbnail button a {
  display: flex;
  flex: 1 auto;
}
.adminRightCol .fileList .fileItem .attachment-thumbnail button {
  position: static;
  display: flex;
  width: 25px;
  height: 25px;
  flex: 1 25px;
  min-width: 25px;
  padding: 0px;
  margin: 0px;
  max-width: 25px;
}
.adminRightCol .fileList .fileItem .attachment-thumbnail button:hover:before {
  content: '';
  display: none;
}
.adminRightCol .fileList .fileItem .attachment-thumbnail button img {
  display: block;
}
.preview-image-cancel {
  position: absolute;
  top: 5px;
  right: 155px;
}

.status {
  display: inline-block;
  width: 50%;
  margin-right: 20px;
}

.card {
  margin-top: 40px;
}

.card h4 {
  font-size: 1.2 rem;
}

.client-list {
  margin-top: 40px;
}
.pagination-section {
  margin-top: 20px;
}
.pagination-section ul.pagination {
  justify-content: center;
}
.pagination-section ul.pagination li {
  margin: 0 2px;
}
.pagination-section ul.pagination li:first-child a,
.pagination-section ul.pagination li:last-child a {
  border-radius: 0px;
}
.pagination-section ul.pagination li a {
  color: var(--admin-primary-color);
}
.pagination-section ul.pagination li.active a {
  background: var(--admin-primary-color);
  border: solid 1px var(--admin-primary-color);
}
.ago,
.like {
  font-family: 'Droid Serif', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.like {
  color: #343a40;
}

.liked {
  color: #32cd32 !important;
}

.fa-smile-o {
  color: #32cd32;
  font-size: 24px;
}

.like-show {
  font-family: 'Droid Serif', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.like-show > .btn:hover {
  text-decoration: underline;
}

.org-list {
  padding: 20px;
  border: 1px solid #eff3f3;
  background: #eff3f3;
  &.disabled {
    select,
    h5,
    label {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}
.colHeader select {
  border-radius: 0px;
  margin: 0px 10px 0 0;
  border-width: 2px;
}
.colHeader button {
  min-width: 120px;
}
.colHeader .col-md-4 {
  justify-content: flex-end;
  margin-right: -15px;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
