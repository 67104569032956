#dashboard {
  background-image: linear-gradient(
    var(--admin-secondary-color),
    var(--admin-secondary-light-color)
  );
  display: flex;
  a {
    color: white;
  }
  .user {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    #user {
      display: flex;
      align-items: center;
      flex-direction: column;
      img {
        width: 120px;
        height: 120px;
        border-radius: 120px;
        border: solid 5px var(--admin-seperate-color);
        background: white;
        margin-bottom: 10px;
      }
      a {
        color: white;
        text-decoration: none;
        &:hover {
          text-decoration: none;
        }
        span {
          color: white;
        }
      }
    }
  }

  .bulbs {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  .navigation {
    flex: 1;
    ul {
      li {
        a {
          display: flex;
          padding: 20px;

          justify-content: center;
          align-items: center;
          border-radius: 15px;
          img {
            width: 60px;
            height: auto;
          }
          span {
            padding-left: 10px;
            font-size: 18px;
          }
          &:hover {
            background: var(--admin-secondary-light-color);
          }
        }
      }
    }
  }
}
