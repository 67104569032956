/* Poll */
#newsfeed {
  .createPollBtnHolder {
    padding-top: 20px;
    padding-left: 50px;
  }
  .pollResult {
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 18px;
      padding-bottom: 15px;
      .totalNo {
        strong {
          font-weight: normal;
          font-size: 12px;
          font-weight: 500;
          padding-left: 5px;
          margin-right: 10px;
        }
      }
      h4.title {
        font-size: 16px;
        font-weight: 500;
        color: var(--black-color);
        margin: 0px;
        margin-left: 52px;
        @media screen and(min-width:320px) and(max-width:480px) {
          margin-left: 0px;
        }
      }
    }
    h4.ques {
      margin-left: 52px;
      font-size: 14px;
      color: var(--black-color);
      padding: 10px 0;
      @media screen and(min-width:320px) and(max-width:480px) {
        margin-left: 0px;
      }
    }
    h4 {
      font-size: 16px;
    }
    .pollOptions {
      .tot {
        display: block;
        width: 30px;
        height: 30px;
        background: #f8f8f8;
        font-size: 14px;
        font-weight: 700;
        line-height: 30px;
        text-align: center;
        margin-left: 10px;
        margin-right: 15px;
      }
    }

    .totalNo {
      strong {
        font-weight: normal;
        font-size: 12px;
        font-weight: 500;
        padding-left: 5px;
        margin-right: 10px;
      }
    }
    .graphHolder {
      display: block;
      background: #f8f8f8;
      height: 10px;
      border: solid 1px white;

      .graphBar {
        background: var(--black-color);
        display: block;
        height: 10px;
      }
    }
  }
  .displayPoll {
    border-top: solid 1px var(--light-gray-color);
    .deletePoll {
      background: none;

      border: none;
      position: absolute;
      right: 0px;
      top: 0px;
    }
    .pollQuestion {
      position: relative;
      &.hide {
        display: none;
      }
      h3 {
        font-size: 16px;
        margin-top: 18px;
        font-weight: 500;
        margin-left: 50px;
      }
      p {
        font-size: 12px;
        padding-left: 50px;
        @media screen and(min-width:320px) and(max-width:480px) {
          padding-left: 0px;
        }
      }
    }
    .pollOptions {
      .graphHolder {
        display: block;
        background: #f8f8f8;
        height: 10px;
        border: solid 1px white;
        .graphBar {
          background: var(--black-color);
          display: block;
          height: 10px;
        }
      }
      .option {
        border: solid 1px var(--light-gray-color);
        padding: 10px;
        margin-bottom: 5px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        div.checkbox {
          &:before {
            width: 13px;
            height: 13px;
          }
          &:after {
            top: 3px;
            left: 3px;
            width: 7px;
            height: 7px;
          }
        }
        .leftSide {
          width: 100%;
          display: flex;
          justify-content: left;
          align-items: center;
          font-size: 14px;
          color: var(--black-color);
        }
        .input {
          width: 39px;
          height: 9px;
          padding-left: 10px;
        }
        .userImg {
          width: 100px;
          min-width: 100px;
          max-width: 100px;
          @media screen and (min-width: 220px) and (max-width: 480px) {
            width: 50px;
            min-width: 50px;
            max-width: 50px;
          }
          img {
            width: 100px;
            height: 100px;
            // Mobile Device Portait
            @media screen and (min-width: 220px) and (max-width: 480px) {
              width: 50px;
              height: 50px;
            }
          }
        }
      }
    }
    .voteBtn {
      display: flex;
      flex-direction: column;
      .btn-dark {
        font-weight: bold;
      }
      .btn-dark:disabled {
        color: white;
        background: var(--border-color);
      }
    }
  }
  #poll {
    header {
      h3 {
        font-size: 20px;
      }
    }

    border-top: solid 1px rgba(0, 0, 0, 0.08);
    position: relative;
    .pollResponse {
      font-size: 12px;

      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      .label {
      }
      .selectbox {
        margin-left: 10px;
        height: 24px;
        line-height: 24px;
        font-size: 12px;
        padding: 0px 5px;
        min-height: 24px;
        border-radius: 0px;
        min-width: 255px;
        align-items: center;
        justify-content: flex-start;
        svg {
          width: 12px;
          height: 12px;
          margin-top: -3px;
        }
        .react-dropdown-select-content {
          height: 24px;
          line-height: 24px;
        }
      }
    }

    .startendinfo {
      font-size: 14px;
      .startAt,
      .endAt {
        .lbl {
          display: inline-block;
          width: 60px;
        }
        button {
          margin: 0px;
          padding: 5px;
          border: none;
          background: transparent;
        }
      }
    }
    .deletePoll {
      padding: 0px;
      background: none;
      border: none;
      position: absolute;
      right: 7px;
      width: 30px;
      top: 3px;
    }
    .closePoll {
      position: absolute;
      top: 0px;
      right: 0px;
      padding: 2px;
      background: none;
      border: none;
      right: 10px;
      top: 3px;
    }
    header {
      h3 {
        font-weight: bold;
      }
    }
    .link {
      position: absolute;

      right: 37px;
      top: 2px;
      img {
        width: 25px;
        height: 25px;
        margin: 3px 0 0 0;
      }
    }
    .pollType {
      display: flex;
      flex-direction: row;
      label {
        padding-right: 15px;
        span {
          padding-left: 10px;
        }
        div.input {
          position: absolute;
          top: 0px;
        }
      }
    }
    .sharing {
      display: flex;
      flex-direction: row;
      font-size: 12px;
      align-items: center;
      margin-top: 6px;
      .dropdown {
        margin-left: 10px;
      }
     
    }
    .cont {
      margin: 0 0 0 0px;
      padding: 0px;
      .pollQuestion {
        font-size: 16px;
        color: var(--black-color);
        margin-bottom: 15px;
        font-weight: 500;
        &.hide {
          display: none;
        }
      }

      .option {
        display: flex;
        flex-direction: row;
        border: solid 2px var(--light-gray-color);
        justify-content: normal;
        padding: 6px;
        font-size: 14px;
        color: var(--gray-color);
        margin: 0 0 10px 0;
        align-items: center;
        font-size: 14px;
        div[contenteditable][placeholder]:empty:before {
          color: var(--black-color);
        }
        .ball {
          display: block;
          width: 9px;
          height: 9px;
          min-width: 9px;
          height: 9px;
          border-radius: 9px;
          border: solid 1px var(--black-color);
          margin-left: 10px;
          margin-right: 5px;
        }
        .btns {
          button {
            padding: 3px;
            border: none;
            background: none;
          }
        }
        .input {
          width: 30px;
        }
        .userImg {
          width: 80px;
          margin-right: 10px;
        }
        input[type='file'] {
          display: none;
        }
        .addBtn {
          margin-left: 9px;
          background: none;
          padding: 0px;
          border: none;

          img {
            width: 15px;
            height: 13px;
          }
        }
      }
      .addOption {
        .addImg {
          padding: 3px;
          margin-right: 2px;
        }
      }
      .userImg {
        display: flex;
        width: 75px;
        height: 75px;
      }
      .pollQuestion {
        &.on {
          color: var(--black-color);
        }
        &.hide {
          display: none;
        }
        h3 {
          font-size: 20px;
          color: var(--gray-color);
          padding-bottom: 13px;
          padding-top: 4px;
        }
        label.label {
          position: relative;
          top: 0px;
        }
      }
      .centerBox {
        display: flex;
        flex: 1 1 auto;
        padding-left: 10px;
        margin: 4px;
        flex-direction: column;
        .memName {
          width: 275px;
          height: 32px;
          line-height: 32px;
          color: var(--gray-color);
          font-size: 18px;
          display: block;
          background: var(--light-gray-color);
          border: none;
          padding: 0 10px;
        }
      }
      .editableBox {
        word-break: break-word;
        span {
          color: var(--primary-color);
        }
        &:focus {
          outline: none;
        }
        &.on {
          color: var(--black-color);
        }
      }
    }
  }

  #poll {
    margin: 15px 0px 0 50px;
    padding: 15px 0 15px 0px;

    .cont {
      padding: 20px 0;
    }
    &[data-started='true'] {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}
/* Mobile Portait */
@media only screen and (max-width: 480px) and (min-width: 220px) {
  #newsfeed {

  
  .pollResult {
    h4.ques,
    h4.title {
      margin-left: 0px;
    }
  }
  .displayPoll {
    .pollQuestion {
      margin: 25px 0;
      font-size: 16px;
      h3 {
        margin-left: 0px;
      }

      p {
        padding-left: 0px;
      }
      div.header {
        h4.title {
          margin-left: 0px;
        }
        p {
          padding-left: 0px;
        }
      }
    }
    .cont {
      // Mobile Portait
    }
  }
  }
  #newsfeed #poll {
    margin-left: 0px;
    .sharing {
      flex-wrap: wrap;
    }
    .pollResponse {
      flex-direction: column;
      justify-content: left;
      align-items: stretch;
      div.label {
        width: 100%;
      }
      .selectbox {
        margin-left: 0px;
      }
    }
    .dropdown {
      min-width: auto;
      margin-left: 0px !important;
      padding-right: 18px;
    }
    .cont {
      margin-left: 0px;
      .memName {
        width: 150px !important;
      }

      .option {
        font-size: 14px;
      }
      input.memName {
        font-size: 14px !important;
      }
    }
  }
}
@media (min-width:220px) and (max-width:319px) {
  #newsfeed {
    .postCreator {
      .postForm{
        .msg.danger{
          margin-left:0px;
        }
        .footer{
          .rightNav{
            li:first-child{
              top:0px;
              left:0px;
            }
          }
        }
      }
     
     
    } 
  }
 
}