.slider {
  display: flex;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 9992;
  width: 100%;
  height: 100%;
  background: black;
  color: white;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .leftBtn {
    background: none;
    padding: 0px;
    margin: 0px;
    position: absolute;
    left: 0%;
    top: 50%;
    margin: -25px 0 0 20px;
    border: none;
    z-index: 999;
    img {
      transform: rotate(90deg);
      width: 50px;
      height: 50px;
    }
  }
  .downloadBtn {
    display: block;
    background: var(--admin-primary-light-color);
    padding: 5px 10px;
    min-width: 150px;
    border-radius: 0px;
    border: none;
    color: white;
    margin-top: 15px;
  }
  .rightBtn {
    z-index: 999;
    background: none;
    padding: 0px;
    margin: 0px;
    position: absolute;
    right: 0%;
    top: 50%;
    margin: -25px 20px 0 0px;
    border: none;
    img {
      transform: rotate(270deg);
      width: 50px;
      height: 50px;
    }
  }
  .closeBtn {
    background: none;
    border: none;
    padding: 0px;
    margin: 0px;
    position: fixed;
    right: 15px;
    top: 15px;
    z-index: 999;
  }
  .top {
    display: flex;
    height: 50px;
    flex: 1 1 50px;
    width: 100%;
    position: relative;
    max-height: 50px;
  }
  .object {
    object-fit: cover;
    max-height: 90%;
    max-width: 90%;
  }

  .attachmentObject {
    object-fit: cover;
    max-height: 90%;
    max-width: 90%;
    width: 90%;
    height: 90%;
    zoom: 2;
  }
  .videoobject {
    object-fit: cover;
    height: 100%;
    width: 100%;
    flex: 1 1 100%;
  }
  .bottom {
    display: flex;
    height: 50px;
    flex: 1 1 50px;
    width: 100%;
    justify-content: center;
    align-items: center;
    max-height: 50px;
  }
}
