.sidebar {
  height: 100vh;
  padding: 40px 0 0 0;
  height: 100%;
  font-family: 'Raleway', Lato, Source Sans Pro, Helvetica Neue, Helvetica,
    sans-serif, Arial;
  background: var(--admin-secondary-color);
  ul li:nth-child(2n) {
    background: rgba(79, 71, 137, 0.21);
  }

  a {
    color: white;
    padding: 10px 20px;
    width: 100%;
    border: none;
    outline: none;
    text-align: left;
    cursor: pointer;
    transition: 0.3s;
    font-size: 17px;
    display: flex;
    justify-content: stretch;
    align-items: center;
    img {
      margin-right: 20px;
    }
    &:hover {
      opacity: 0.6;
    }
    i {
      margin-right: 10px;
    }
  }
}
.profilePic {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0;
  background: var(--admin-secondary-color);
  .img {
    width: 120px;
    height: 120px;
    border-radius: 120px;
    border: solid 5px var(--admin-seperate-color);
    background: white;
    margin-bottom: 10px;
  }
  a {
    text-decoration: none;
  }
  span {
    color: white;
  }
}
