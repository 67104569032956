.colHeader {
  display: flex;
  font-family: 'Raleway', Lato, Source Sans Pro, Helvetica Neue, Helvetica,
    sans-serif, Arial;
  border-bottom: solid 0px var(--admin-border-color);
  justify-content: flex-end;
  align-items: center;
  min-height: 100px;
  @media (min-width: 320px) and (max-width: 767px) {
    flex-wrap: wrap;
  }
  h1 {
    margin: 0px;
  }
  select {
    border-radius: 0px;
    margin: 0px 10px 0 0;
    border-width: 2px;
    width: 200px;
  }

  button {
    min-width: 120px;
  }
  .leftSide {
    flex: 3;
    font-family: 'Raleway', Lato, Source Sans Pro, Helvetica Neue, Helvetica,
      sans-serif, Arial;
  }
  .rightSide {
    flex: 1 1 auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    @media (min-width: 320px) and (max-width: 767px) {
      justify-content: flex-start;
    }
  }
}
.searchBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 10px;
  select {
    border-radius: 0px;
    margin: 0px 10px 0 0;
    border-width: 2px;
    width: 200px;
  }
  input[type='text'] {
    border-radius: 0px;
    border: solid 2px #ced4da;
    margin: 0px 10px 0 0;
    border-width: 2px;
    min-width: 350px;
    max-width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }
}
