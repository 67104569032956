/*
==================================================================
                        Each Post
==================================================================
*/
#newsfeed {
  .post {
    font-family: 'Inter', sans-serif;
    background: white;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    display: block;
    margin-bottom: 25px;
    flex-direction: column;
    button {
      font-family: 'Inter', sans-serif;
    }

    .shortInfo {
      font-size: 12px;
      margin: 0 5px 0px 5px;
      padding: 15px 0 15px 0;
      border-top: solid 1px var(--border-color);
      display: flex;
      justify-content: flex-start;
      align-items: center;
      img {
        margin-right: 2px;
      }
      .tinyinfo {
        padding-left: 10px;
      }
      .icons {
        position: relative;
        .postReactList {
          display: none;
        }
        &:hover {
          .postReactList {
            display: block;
          }
        }
      }
    }
    .actionButtons {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin: 0 18px 0px 18px;
      padding: 15px 0 20px 0;
      border-top: solid 1px var(--border-color);
      .rightSide {
        display: flex;
        flex-direction: row;
        button {
          margin-left: 20px;
          position: relative;

          &.item {
            font-family: 'Inter', sans-serif;
          }
          i {
            border: solid 1px var(--primary-color);
            position: absolute;
            font-style: normal;
            font-size: 10px;
            background: white;
            min-width: 17px;
            height: 17px;
            text-align: center;
            line-height: 12px;
            border-radius: 12px;
            left: 9px;
            top: -6px;
            padding: 3px;
          }
        }
      }
      .leftSide {
        position: relative;
        .btns {
          position: absolute;
          top: -16px;
          display: flex;
          flex-direction: row;
          align-items: center;

          button {
            width: 35px;
            height: 35px;
            margin-right: 10px;
          }
        }
      }
      button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background: none;
        border: none;
        img {
          width: 23px;
          height: auto;
        }
        span {
          margin-left: 10px;
          font-size: 12px;
        }
      }
      .likeme {
        width: 35px;
        height: 35px;
        background: url('/img/thumbs-init.svg') no-repeat;
        background-size: 100%;
        span {
          position: absolute;
          left: -999em;
        }
        &:hover {
          background: url('/img/thumbs-active.svg') no-repeat;
        }
      }

      .interest {
        width: 35px;
        height: 35px;
        background: url('/img/interest-init.svg') no-repeat;
        background-size: 100%;
        span {
          position: absolute;
          left: -999em;
        }
        &:hover {
          background: url('/img/interest.svg') no-repeat;
        }
      }

      .laugh {
        width: 35px;
        height: 35px;
        background: url('/img/laugh-init.svg') no-repeat;
        background-size: 100%;
        span {
          position: absolute;
          left: -999em;
        }
        &:hover {
          background: url('/img/laugh.svg') no-repeat;
        }
      }
    }
  }
  /*
==================================================================
               Attachment File List on post
==================================================================
*/
  .fileList {
    display: flex;
    margin: 0 18px;
    flex-direction: column;
    padding: 10px 0;
    .item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      min-height: 54px;
      background: var(--light-gray-color);
      border-bottom: solid 4px white;
      font-size: 14px;
      line-height: 18px;
      .icon {
        display: flex;
        width: 82px;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        a {
          color: var(--black-color);
          text-decoration: none;
        }
      }
      .desc {
        display: flex;
        width: 100%;
        flex: 1 1 auto;

        align-items: center;
        border-left: solid 4px white;
        padding: 18px;
        a {
          color: var(--black-color);
          text-decoration: none;
          display: block;
    width: calc(100% - 31px);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
        }
      }
    }
  }
  .viewAllFiles {
    display: flex;
    justify-content: center;
    height: 26px;
    background: var(--light-gray-color);
    color: var(--primary-color);
    font-size: 12px;
    line-height: 16px;
    border: none;
    padding: 0px;
    width: calc(100% - 36px);
    margin: 0 18px;
  }
}

.postCarousel {
  .rec {
    button {
      position: absolute;
      background: white;
      border-radius: 30px;
      height: 30px;
      width: 30px;
      border: solid 2px var(--border-color);
      &.rec-arrow-right {
        float: right;
        position: absolute;
        right: 0px;
      }
    }
  }
}


@media only screen and (min-width: 220px) and (max-width: 480px) {
  #newsfeed {
    .fileList{
      margin:0 5px;
      .item {
        min-height:auto;
        .desc{
          padding:10px;
        }
        .icon{
          min-width: 32px;
          max-width: 32px;
          img{
            width:25px;
            height:auto;
          }
        }
      }
    } 
    .post{
      .actionButtons{
        margin:0 5px;
      }
      .commentBox{
        .commentsMsg{
          padding:5px 5px 5px 0px
        }
        .userComment{
          padding:0 0 0 5px;
        }
        .commentForm{
          min-height:40px;
          .leftSide{
            padding-left:5px;
            div.img{
              padding-top:5px;
            }
            .comment{
              padding:10px 5px 0 5px;
              min-height:35px;
            }
          }
          .rightSide{
            padding-top:5px;
          }
        }
      }
    }
    .displayPoll{
      .pollQuestion{
        margin:10px 0;
      }
      .cont{
        padding:0 5px 10px 5px;
        .pollOptions{
          .option{
            padding:5px 0;
          }
        }
        .voteBtn{
          margin:0px;
          button{
            margin:0px;
          }
          
        }
      }
    }
  }
 
}