.carousel-wrapper {
  position: relative;

  button {
    height: 30px;
    width: 30px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    border-radius: 50%;
    border: 2px solid #fff;
    color: #fff;
    background-color: transparent;

    // &:hover {
    //   background-color: #0284c7;
    //   border-color: #0284c7;
    // }

    &.small {
      width: 25px !important;
      height: 25px !important;
      font-size: 12px;
    }
  }

  .content {
    width: 100px;

    .video-div {
      max-width: 100px;
      .inside-video {
        width: 100px;
        position: relative;

        .playBtn {
          display: flex;
          width: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 9;
          justify-content: center;
          align-items: center;
          img {
            width: 36px;
            height: 36px;
          }
        }

        video {
          width: 100px;
          height: 100px;
          object-fit: contain;
        }

        button {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 10;
          border-radius: 50%;
          width: 30px;
          height: 30px;
          background-color: whitesmoke;
          border: none;
          cursor: pointer;

          img {
            width: 100%;
            height: 100%;
            stroke-width: 3;
          }
        }
      }
      .inputField-div {
        p {
          font-size: 10px;
          font-weight: 700;
          height: 60px;
        }
        .inputField {
          width: 100px;
          height: 30px;
          background-color: whitesmoke;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 0.5px solid black;
          cursor: pointer;
        }
        .thumbImg {
          position: relative;

          .inputImage {
            width: 100px;
            height: 100px;
          }
        }

        button {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 10;
          border-radius: 50%;
          width: 30px;
          height: 30px;
          background-color: whitesmoke;
          border: none;
          cursor: pointer;
          display: none;

          .close {
            width: 15px;
            height: 15px;
            margin: 8px 8px 0 0;
          }
        }

        &:hover button {
          display: block;
        }
      }
    }

    .attachment-div {
      max-width: 100px;
      .inside-attachment {
        width: 100px;
        position: relative;

        .fileLink {
          width: 100px;
          height: 100px;
          object-fit: contain;
          position: relative;

          .doc {
            position: absolute;
            top: 18%;
            left: 50%;
            transform: translateX(-50%);
          }

          .attachment-name {
            position: absolute;
            top: 50%;
            left: 5%;
            transform: translateY(-50%, -50%);
            font-size: 10px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: block;
            color: gray;
          }
        }
      }
    }

    .inside-image {
      width: 100px;
      height: 100px;
      position: relative;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      button {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 10;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        background-color: whitesmoke;
        border: none;
        cursor: pointer;

        img {
          width: 100%;
          height: 100%;
          stroke-width: 3;
        }
      }
    }

    .inside-attachment {
      width: 100px;
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: whitesmoke;

      .fileLink {
        object-fit: contain;
        border: 1px solid rgb(247, 234, 234);
      }
      button {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 10;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        background-color: whitesmoke;
        border: none;
        cursor: pointer;

        img {
          width: 100%;
          height: 100%;
          stroke-width: 3;
        }
      }
    }
  }

  .second {
    width: 100px;
    input {
      width: 100px;
    }
    .img1 {
      position: relative;

      .mainImg {
        width: 100px;
        height: 100px;
        object-fit: contain;
      }
    }

    button {
      display: none;
    }

    &:hover button {
      display: block;
    }

    button {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 10;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      background-color: whitesmoke;
      border: none;
      cursor: pointer;

      .close {
        width: 100%;
        height: 100%;
        stroke-width: 3;
      }
    }
  }
}

.carousel-container {
  &::-webkit-scrollbar {
    display: none;
  }
}
