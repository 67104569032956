.navholder {
  font-family: 'Raleway', Lato, Source Sans Pro, Helvetica Neue, Helvetica,
    sans-serif, Arial;
  background: black;
  nav {
    border-radius: 0px;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
    min-height: 50px;
    .navbarbrand {
      width: 120px;
      float: left;
      padding: 10px;
      border-bottom: none;
      img {
        max-width: 100%;
      }
    }
  }
  a {
    color: white;
  }
}
