:root {
  --gray-color: #9197a3;
  --light-gray-color: #f8f8f8;
  --black-color: #404244;
  --blue-color: #21a3e5;
  --border-color: #e6e6e6;
  --secondary-color: #e6e6e6;
  --primary-color: #21a3e5;
  --olive-color: #1e1e1e;
}
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');
body,
html {
  background: transparent;
}

/* Bootstrap CSS Override */
button {
  &:hover {
    img {
      opacity: 0.5;
    }
  }
}
#newsfeed {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;

  .btn {
    border: none;
    border-radius: 0px;
    font-size: 14px;
    color: white;
    font-weight: bold;
    height: 30px;
    line-height: 30px;
    background: var(--blue-color);
    padding: 0 23px;
    border: none;
    &.btn-secondary {
      background: var(--border-color);
      color: var(--black-color);
    }
    &.btn-primary {
      &:disabled {
        background: var(--border-color);
      }
    }
    &.btn-dark {
      background: var(--black-color);
      color: white;
      font-weight: normal;
      &:disabled {
        background: var(--border-color);
      }
    }
    &:disabled {
      color: white;
    }
  }

  label.label,
  div.label {
    position: relative;
    padding-right: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0px;
    padding: 0px;
    button.radioBtn {
      position: relative;
      z-index: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      background: none;
      outline: none;
      div {
        display: block;
        width: 16px;
        height: 16px;
        background: white;
        border-radius: 16px;
        border: solid 2px var(--primary-color);
        content: '';
        position: relative;
        left: 0px;
        top: 0px;
        visibility: visible;
        z-index: 10;
        margin-right: 5px;
      }
      &.active div:after {
        display: block;
        width: 6px;
        height: 6px;
        background: var(--primary-color);
        border-radius: 6px;
        left: 3px;
        top: 3px;
        content: '';
        position: absolute;
        z-index: 11;
      }
    }
    input {
      position: relative;
      z-index: 1;
      opacity: 0;
      & ~ div:before {
        display: block;
        width: 9px;
        height: 9px;
        background: white;
        border-radius: 9px;
        border: solid 1px var(--primary-color);
        content: '';
        position: absolute;
        left: 0px;
        top: 0px;
        visibility: visible;
        z-index: 10;
      }
      &:checked ~ div:before {
        background: var(--primary-color);
      }
    }
    input[type='radio'] {
      & ~ div:before {
        width: 9px;
        height: 9px;
        border-radius: 15px;
      }
      &:checked ~ div:after {
        border-radius: 6px;
        left: 5px;
        top: 5px;
      }
    }
    input[type='checkbox'] {
      & ~ div:before {
        border-radius: 0px;
        background: none;
      }
      & ~ div:after {
        left: 2px;
        border-radius: 6px;
        top: 2px;
        position: absolute;
        width: 5px;
        height: 5px;
        background: transparent;
        display: block;
        border-radius: 0px;
        content: '';
      }
      &:checked ~ div:after {
        background: var(--primary-color);
      }
    }
  }

  .poll.disabled {
    input[type='radio'],
    input[type='checkbox'] {
      & ~ div:before {
        border-color: var(--light-gray-color-color);
      }
      &:checked ~ div:after {
        background: var(--light-gray-color-color);
      }
    }
  }
  /*
      #newsfeed{
          height:100%;
          padding-top:20px;
      }
      */
  .shadowBox {
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
    background: white;
  }

  .dropDown {
    display: flex;
    flex-direction: column;
    background: var(--light-gray-color);
    padding: 2px 8px;
    position: relative;
    min-width: 255px;
    font-size: 12px;
    font-weight: normal;
    .selectedItem {
      display: flex;
      justify-content: start;
      .alphabets {
        display: block;
        width: 28px;
        margin-left: 0px;
        padding-right: 10px;
        color: #c2c2c3;
        text-decoration: underline;
        width: 25px;
        text-align: left;
      }
      .rightside {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
      }
    }
    .dropList {
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 27px;
      background: var(--light-gray-color);
      width: 100%;
      left: 0px;
      z-index: 9;

      .item {
        display: flex;
        flex-direction: row;
        padding: 5px 0;
        border-top: solid 1px #eeeeee;

        .alphabets {
          margin-left: 8px;
          padding-right: 10px;
          color: #c2c2c3;
          text-decoration: underline;
          width: 25px;
          text-align: right;
          display: block;
          float: left;
        }
        .checkbox {
          padding: 0px;
        }
      }
    }
  }

  .options {
    position: relative;
    .optionsPop {
      display: none;
      min-width: 170px;
      flex-direction: column;
      border: solid 1px var(--border-color);
      position: absolute;
      right: -5px;
      top: -4px;
      z-index: 9;
      background: white;
      h4 {
        font-size: 12px;
        font-weight: bold;
        text-align: left;
        padding: 0 0 0 12px;
        height: 33px;
        align-items: center;
        line-height: 33px;
        border-bottom: solid 1px var(--border-color);
        margin: 0px;
      }
      .item {
        border-bottom: solid 1px var(--border-color);
        background: white;
        padding: 0px;
        button {
          background: none;
          position: relative;
          padding: 0px;
          border: none;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: left;
          width: 100%;
          font-size: 14px;
          height: 35px;
          span {
            display: block;
            width: 12px;
            height: 12px;
            border-radius: 0px;
            border: solid 1px var(--border-color);
            margin-right: 5px;
            margin-left: 5px;
          }
          &.active {
            span:before {
              content: '';
              display: block;
              height: 6px;
              width: 6px;
              border-radius: 0px;
              background: var(--primary-color);
              position: relative;
              top: 2px;
              left: 2px;
            }
          }
          &.linkonly {
            padding-left: 18px;
          }
        }
        label {
          margin: 0px;
          font-size: 12px;
          padding: 5px 12px;
        }
        button.linkto {
          font-size: 12px;
          text-align: left;
          padding: 5px 5px 5px 12px;
          margin: 0px;
          background: none;
          border: none;
          width: 100%;
        }
        .box {
          flex: 1 0 auto;
        }
        .role {
          width: 100%;
        }
      }
    }
    &:hover {
      img {
        position: relative;
        z-index: 10;
      }
      .optionsPop {
        display: flex;
      }
    }
  }

  button.link {
    padding: 0px;
    margin: 0px;
    background: none;
    border: none;
    &:focus {
      outline: none;
    }
  }

  .checkbox,
  .radiobox {
    margin: 0px;
    font-size: 12px;
    padding: 5px 12px;
    display: flex;
    flex-direction: row;
    justify-content: left;
    text-align: left;
    align-items: center;
    .role {
      padding-left: 10px;
      display: flex;
      flex: 1 1 auto;
    }

    .box {
      input {
        position: absolute;
        z-index: 5;
        top: 0px;
        left: 0px;
        opacity: 0;
        & ~ div {
          border: solid 1px var(--border-color);
          display: flex;
          width: 12px;
          height: 12px;
          background: white;
          position: relative;
        }
        &:checked ~ div:after {
          display: block;
          width: 8px;
          height: 8px;
          background: var(--primary-color);
          position: absolute;
          top: 1px;
          left: 1px;
          content: '';
        }
      }
    }
    &.selected {
      .box {
        &:after {
          background-color: var(--blue-color);
        }
      }
    }
    &.disabled {
      pointer-events: none;
      .box {
        &:after {
          background-color: white;
        }
      }
      .role {
        color: var(--border-color);
      }
    }
  }
  .radiobox {
    .box {
      border-radius: 12px;
      input[type='radio'] ~ div {
        border-radius: 12px;
      }
      input[type='radio']:checked ~ div:after {
        border-radius: 8px;
      }
    }
  }
}

.xs-only {
  display: none;
  @media only screen and (min-width: 220px) and (max-width: 480px) {
    display: block;
  }
}
/* Mobile Portait */
@media only screen and (min-width: 220px) and (max-width: 480px) {
}

[contenteditable][placeholder]:empty:before {
  content: attr(placeholder);
  position: absolute;
  color: gray;
  background-color: transparent;
}

/* For WebKit browsers (Chrome, Safari) */
video::-webkit-media-controls-fullscreen-button {
  display: none;
}

/* For Firefox */
video::-moz-media-controls-fullscreen-button {
  display: none;
}
