.orgList{
    list-style:none;
    margin:0px;
    padding:0px;
    display: flex;
    flex-wrap: wrap;
    li{
        min-width: calc(50% - 20px );
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border:solid 1px #ccc;
        margin:5px;
        padding:5px;
        img{
            cursor: pointer;
        }

    }
}