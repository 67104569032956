.bp-form {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.formholder {
  width: 100%;
  display: flex;
  justify-content: center;
}
.disabled{
 
  pointer-events: none;
  input{
    background:#e9ecef;
    border: 1px solid #ced4da;
  }
}
#clientList,
.clientList {
  font-family: 'Raleway', Lato, Source Sans Pro, Helvetica Neue, Helvetica,
    Arial;

  .contLeftSide {
    padding-right: 0px;
    @media (max-width: 767px) {
      padding-right: 15px;
    }
    display: flex;
    & > div {
      flex: auto;
    }
  }

  .contRightSide {
    display: flex;
    padding-left: 0px;
    .orgList{
      .orgName{
        display: block;
        border:solid 1px #ccc;
        padding:5px;
        word-break: break-word; 
      }
      
    }
    @media (max-width: 767px) {
      padding-left: 15px;
    }
    .orgList{
      padding:35px 0;
      
    }
    .card {
      flex: auto;
      border-left: solid 0px #fff;
      @media (max-width: 767px) {
        border-left: solid 1px var(--admin-border-color);
      }
      border-radius: 0px;
      margin-top: 20px;
      .card-header {
        background: none;
        padding-top: 16px;
        border: none;
        align-items: center;
        h4 {
          font-size: 20px;
          font-weight: 600;
          padding-right:10px;
        }
        .col-md-12 {
          justify-content: space-between;
          display: flex;
          a.btn {
            min-width: 150px;
            border: none;
            padding: 0px;
            border-radius: 0px;
            background: var(--admin-seperate-color);
            line-height: 35px;
            height: 35px;
            margin: 0px;
            justify-content: center;
            align-items: center;
            font-size: 16px;
            color: #1a1a1a;
          }
        }
      }
      .card-body {
        justify-content: center;
        display: flex;
        .bp-form {
          display: block;
          width: 70%;
          .tenants {
            label {
              div {
                display: inline-block;
                input {
                  margin-right: 5px;
                }
              }
            }
          }
        }
        label {
          font-size: 18px;
          margin-top: 15px;
        }
        input[type='text'],
        select {
          border-radius: 0px;
          border-width: 2px;
          height: 40px;
        }
      }
      .card-footer,
      .cardfooter {
        display: flex;
        justify-content: center;
        background: none;
        border: none;
        text-align: center;
        button {
          min-width: 150px;
          border: none;
          height: 40px;
          border-radius: 0px;
          background: var(--admin-primary-color);
          &.btn-secondary {
            background: var(--admin-primary-light-color);
          }
        }
      }
      .card .bp-form {
        width: 60%;
      }
    }
    .orgListBlock{
      display:block;
      height: 344px;
      overflow: auto;
    }
  }

  .holder {
    display: flex;
    flex: auto;
    flex-direction: column;
    margin-top: 20px;
    border: solid 1px var(--admin-border-color);

    .header {
      padding: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      h3 {
        font-size: 20px;
        font-weight: 600;
        margin: 0px;
      }
      .viewAllBtn {
        border: none;
        background: var(--admin-seperate-color);
        margin: 0px;
        padding: 0px 10px;
        height: 35px;
        line-height: 35px;
      }
    }
    ul {
      display: flex;
      flex-direction: column;
      border-radius: 0px;
      margin: 0px;
      padding: 0px;
      overflow-y: auto;
      height: 652px;
      overflow-x: hidden;
    }

    .listgroupitem {
      display: flex;
      flex-direction: row;
      align-items: center;
      border: none;
      border-radius: 0px;
      padding: 10px 15px;
      position: relative;
      border-bottom: solid 1px var(--admin-lightgray-color);
      .clienticon {
        width: 60px;
        height: 60px;
        border: solid 1px var(--admin-border-color);
        display: flex;
        justify-content: center;
        border-radius: 60px;
        background: white;
        overflow: hidden;
        img.icon {
          width: 60px;
          height: 60px;
          border-radius: 60px;
        }
        @media (min-width: 320px) and(max-width: 992px) {
          display: none;
        }
      }
      h5 {
        font-weight: normal;
        font-size: 18px;
        word-break: break-word;
        span {
          font-size: 14px;
          display: block;
          color: #666;
        }
      }
      .clientinfo {
        display: flex;
        padding-left: 10px;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        flex: 1;
      }
      .iconBtn {
        border: none;
        background: none;
        padding: 5px;
        margin: 0px;
      }
      &:first-child {
        border-top: solid 1px var(--admin-lightgray-color);
      }

      &.active:after {
        /*
        content: '';
        display: block;
        height: 20px;
        position: absolute;
        right: -11px;
        top: 50%;
        width: 20px;
        border-top: solid 1px var(--admin-border-color);
        border-right: solid 1px var(--admin-border-color);
        border-bottom: solid 0px var(--admin-border-color);
        border-left: solid 0px var(--admin-border-color);
        z-index: 9;
        background: var(--admin-lightest-color);
        transform: rotate(45deg);
        margin-top: -10px;
        @media (min-width: 320px) and(max-width: 768px) {
          display: none;
        }
        */
      }
      &.active,
      &:hover {
        background: var(--admin-lightest-color);
      }
    }
  }
}

.listbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: none;
  border-radius: 0px;
  padding: 15px;
  position: relative;
  margin: 0 0 15px 0;
  border: solid 1px var(--admin-border-color);

  .clienticon {
    width: 60px;
    height: 60px;
    border: solid 1px var(--admin-border-color);
    display: flex;
    justify-content: center;
    border-radius: 60px;
    background: white;
    @media (min-width: 320px) and(max-width: 768px) {
      display: none;
    }
  }
  h5 {
    font-weight: normal;
    font-size: 18px;
  }
  .clientinfo {
    display: flex;
    padding-left: 10px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex: 1;
  }
  .iconBtn {
    border: none;
    background: none;
    padding: 5px;
    margin: 0px;
  }
  &:first-child {
  }
  &:nth-child(2n) {
    background: var(--admin-lightgray-color);
  }

  &.active,
  &:hover {
    background: var(--admin-lightest-color);
  }
}

.org-list h5 {
  font-weight: 600;
  font-size: 20px;
}

.rdtCounter:nth-child(3) {
  pointer-events: none;
  opacity: 0.7;
}

#schedulePicker {
  clear: both;
  background: var(--light-gray-color);
  padding: 30px;
  display: flex;
  flex-direction: column;
  margin-bottom: 1px;
  h3 {
    font-size: 20px;
  }
  .colon {
    padding: 0 10px;
  }
  .selectbox {
    height: 30px;
    border-radius: 0px;
    background-color: var(--light-gray-color);
    border: none;
    font-size: 14px;
    &.am {
      margin: 0 5px;
    }
    &.timezone {
      margin: 0 15px;
    }
    &.month {
      min-width: 217px;
      padding-left: 10px;
    }
  }
  .calendarConfig {
    background: white;
    display: flex;
    flex-direction: row;
    min-height: 70px;
    padding: 20px 30px;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 1px;
    flex-wrap: wrap;
    .hour,
    .minute {
      width: 40px;
      height: 30px;
      background-color: var(--light-gray-color);
      border: none;
      text-align: center;
    }
    .leftConfig {
      display: flex;
    }
  }
  .displayCalendar {
    padding-top: 20px;
    background: white;
    padding: 0 30px 20px 30px;
    display: flex;
    flex-direction: column;
    ul.weeks {
      margin: 0px;
      padding: 20px 0 10px 0;
      list-style: none;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
    }
    ul.days {
      display: block;
      margin: 0px;
      padding: 0px;
      list-style: none;

      li {
        width: 14.28%;
        display: block;
        float: left;
        text-align: center;
        padding: 6px 0;

        &.previousMonthDate,
        &.nextMonthDate,
        &.disabled {
          color: #9197a3;
          pointer-events: none;
          &.current {
            pointer-events: none;
          }
        }
        &.current {
          cursor: pointer;
          color: var(--primary-color);
        }
        &.active {
          cursor: pointer;
        }
        &.active.selected span {
          background: var(--primary-color);

          color: white;
        }
        &.active:hover span {
          background: var(--light-gray-color);

          color: var(--black-color);
        }
        span {
          display: inline-block;
          min-width: 30px;
          min-height: 30px;
          border-radius: 30px;
          line-height: 30px;
        }
      }
    }
  }
  .actionControl {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px 0px;
    .leftSide {
      button {
        margin-right: 5px;
      }
    }
    button {
      margin: 0px;
    }
    .rightSide {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .selectedDate {
        padding-right: 12px;
        font-size: 12px;
      }
    }
  }
}
.groupTableHolder{
  padding:0 17px;
  max-height:400px;
  overflow: auto;
  display: block;
  width:100%;
}
table.groupList{
  width:100%;
  border-bottom: solid 1px var(--admin-lightgray-color);
  border-left: solid 1px var(--admin-lightgray-color);
  thead td {
    font-weight: 600;
    &:nth-child(1){
      width:50px;
    }
    &:nth-child(2){
      width:160px;
    }
  }
  td {
    border-top: solid 1px var(--admin-lightgray-color);
    border-right: solid 1px var(--admin-lightgray-color);
    padding:5px;
    word-break: break-word;
    &.name{
      word-break: break-word;
    }
}
}
.smallTxt {
  font-size:12px;
  b{
    font-weight:600;
  }
}