.bordered{
  border:solid 1px black
}
.card {
  font-family: 'Raleway', Lato, Source Sans Pro, Helvetica Neue, Helvetica,
    Arial;
  display: flex;
  padding: 15px;

  flex-direction: column;
  margin: 0px 0;
  &.even {
    background: rgba(0, 0, 0, 0.03);
  }
  &.odd {
    background: white;
  }
 
  .cardheader {
    display: flex;
    justify-content: space-between;
    align-items: start;
    flex-direction: row;
    flex: 1;

    .headerLeft {
      display: flex;
      flex-direction: column;
      .title {
        font-size: 18px;
        font-weight: 600;
        margin: 0px;
        margin-top: 5px;
        &:hover {
          cursor: pointer;
        }
      }

      .headerInfo {
        font-size: 13px;
        display: flex;
        color: #999999;

        .createdAt {
        }

        .postedBy {
          padding-left: 10px;
        }
      }
    }
    .headerRight {
      display: flex;
      .btn {
        margin: 0px;
        padding: 5px;
        background: transparent;
        border: none;
        i {
          color: #999;
        }
      }
      .activeBtn {
        margin: 0px;
        padding: 5px;
        background: transparent;
        border: none;
        i {
          color: #333;
        }
      }
    }
  }
  .cardbody {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 20px 0 0px 0;
    strong {
      font-weight: 600;
    }
  }
  .cardfooter {
    display: flex;
    flex: 1;
    .btn {
      display: block;
      min-width: 150px;
      height: 40px;
      line-height: 40px;
      color: #ffffff;
      justify-content: center;
      align-items: center;
      background: black;
      margin: 0px 10px 0 0;
      padding: 0px 10px;
      border: none;
      font-size: 18px;
      text-align: center;
      &:hover {
        text-decoration: none;
        opacity: 0.9;
      }
      i {
        color: #ffffff;
      }
    }
  }
}
.btnHolder {
  display: flex;
  padding: 10px 0 0 0;
  .primaryBtn {
    background-color: #343a40;
    border-color: #343a40;
    color: #fff;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    border: 1px solid transparent;
    padding: 5px 10px;
    font-size: 1rem;
    line-height: 1.5;
    margin-right: 5px;
    text-decoration: none;
    &:hover {
      text-decoration: none;
      opacity: 0.8;
    }
  }
}
